<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'apps-stocklock-edit'}"
                class="hidden"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :order-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :order-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(product_id)="data">
          [#{{data.item.product_id}}] {{data.item.name}}{{isGift(data.item.is_gift)}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(is_free)="data">
          {{getCodeLabel('yesno', data.item.is_free)}}
        </template>

        <template #cell(startTime)="data">
                {{toDate(data.item.starttime)}} 至  {{toDate(data.item.endtime)}}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_lock', data.item.status)}`"
          >
            {{getCodeLabel('stock_lock', data.item.status)}}
          </b-badge>
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
          <br>
          {{toDate(data.item.add_time)}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}}
          <br>
          {{toDate(data.item.modify_time)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- 如果已释放 -->
            <div v-if="data.item.is_free == 1">
              <b-dropdown-item>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">无菜单</span>
              </b-dropdown-item>
            </div>
            <div v-else>
              <!-- 创建人的菜单 -->
              <div v-if="data.item.creator == userid">
                <div v-if="data.item.status == 0">
                  <b-dropdown-item @click="submitapply(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">提交审核</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="cancelapply(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">取消锁库</span>
                  </b-dropdown-item>
                </div>
                <div v-if="data.item.status == 1">
                  <b-dropdown-item @click="cancelapply(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">取消锁库</span>
                  </b-dropdown-item>
                </div>
                <div v-if="data.item.status == 2">
                  <b-dropdown-item @click="showFreeLockQty(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">释放锁库</span>
                  </b-dropdown-item>
                </div>
              </div>
              <!-- 运营主管的菜单 -->
              <div v-if="checkRole(roleid)">
                <div v-if="data.item.status == 1">
                  <b-dropdown-item @click="showLockModal(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">审核通过</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="checkFaild(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">审核失败</span>
                  </b-dropdown-item>
                </div>
                <div v-if="data.item.status == 2">
                  <b-dropdown-item @click="addtostock(data.item.stocklock_id)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">追加锁库</span>
                  </b-dropdown-item>
                </div>
              </div>
            </div>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>



      <b-modal
          id="freeLockModal"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          hide-footer
          centered
          size="md"
          :title="'库存锁库申请'"
      >
        <validation-observer ref="simpleRules1">
          <b-form>
            <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="释放数量"
                label-for="input-default"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required|integer"
                  name="Number"
              >
                <b-form-input
                    v-model="qtyfree"
                    :state="errors.length > 0 ? false:null"
                    @change="checkmaxfree()"
                />
                <small class="text-danger" v-if="errors[0]">请输入数字</small>
              </validation-provider>


            </b-form-group>

            <b-button
                variant="primary"
                type="submit"
                @click.prevent="FreeSucess"
            >
              提 交
            </b-button>
          </b-form>
        </validation-observer>

      </b-modal>

      <b-modal
          id="stocklockModal"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          hide-footer
          centered
          size="lg"
          :title="'库存锁库审核'"
      >
        <validation-observer ref="simpleRules2">
          <b-form>
            <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="锁库数量"
                label-for="input-default"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required|integer"
                  name="Number"
              >
                <b-form-input
                    v-model="qtylock"
                    :state="errors.length > 0 ? false:null"
                    @change="checkmax()"
                />
                <small class="text-danger" v-if="errors[0]">请输入数字</small>
              </validation-provider>


            </b-form-group>

            <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="开始时间"
                label-for="input-default"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="starttime"
              >
                <flat-pickr
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                    v-model="starttime"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger" v-if="errors[0]">请输入开始时间</small>
              </validation-provider>



            </b-form-group>

            <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="结束时间"
                label-for="input-default"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="endtime"
              >
                <flat-pickr
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                    v-model="endtime"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger" v-if="errors[0]">请输入结束时间</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="申请原因"
                label-for="input-default"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="reason"
              >
                <b-form-input
                    id="reason"
                    size="sm"
                    v-model="reason"
                    readonly
                />
                <small class="text-danger" v-if="errors[0]">请输入申请原因</small>
              </validation-provider>
            </b-form-group>

            <b-button
                variant="primary"
                type="submit"
                @click.prevent="ApplySucess"
            >
              审核通过
            </b-button>
          </b-form>
        </validation-observer>

      </b-modal>

      <b-modal
          id="addLockModal"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          hide-footer
          centered
          size="md"
          :title="'追加库存锁库'"
      >
        <validation-observer ref="simpleRules3">
          <b-form>
            <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="追加数量"
                label-for="input-default"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required|integer"
                  name="addfree"
              >
                <b-form-input
                    v-model="addfree"
                    :state="errors.length > 0 ? false:null"
                    @change="checkmaxaddfree()"
                />
                <small class="text-danger" v-if="errors[0]">请输入数字</small>
              </validation-provider>


            </b-form-group>

            <b-button
                variant="primary"
                type="submit"
                @click.prevent="addSucess"
            >
              提 交
            </b-button>
          </b-form>
        </validation-observer>

      </b-modal>


    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BForm,BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import stocklockUseList from './stocklockUseList'
import stocklockStore from './stocklockStore'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,

    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      userid:0,
      roleid:0,
      stocklockid:0,
      qtyfree:0,
      stockid:0,
      qtylock:0,
      starttime:"",
      endtime:"",
      reason:"",
      addfree:0,
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('stocklock/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    checkRole:function (id){
      let idstr = "," + id + ","
      if(String(idstr).indexOf(",14,") >= 0 || String(idstr).indexOf(",4,") >= 0){
        return true
      }else{
        return false
      }
    }
  },
  setup() {

    const router = useRouter()
    let productid = router.route.value.query.productid
    let creator = router.route.value.query.creator
    console.log('productid',productid)

    const toast = useToast()
    // Register module
    if (!store.hasModule('stocklock')) store.registerModule('stocklock', stocklockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stocklock')) store.unregisterModule('stocklock')
    })

    const submitapply = function (stocklockid){
      store
          .dispatch('stocklock/checkStock', {stocklockid: stocklockid})
          .then(res => {
            if(res.data.code == 0){
              if(res.data.data == "true"){
                store
                    .dispatch('stocklock/submitcheck', {stocklockid: stocklockid})
                    .then(res => {
                      if(res.data.code == 0){
                        toast.success("提交成功")
                        refetchData()
                      }else {
                        toast.error("提交失败!")
                      }
                    })
              }
            }
          })
    }

    const cancelapply = function (stocklockid){
      store
          .dispatch('stocklock/cancelapply', {stocklockid: stocklockid})
          .then(res => {
            if(res.data.code == 0){
              toast.success("取消成功")
              refetchData()
            }else {
              toast.error("取消失败!")
            }
          })
    }

    const showFreeLockQty = function (stocklockid){
      this.$bvModal.show('freeLockModal')
      this.stocklockid = stocklockid
      store
          .dispatch('stocklock/getstocklockinfo', {stocklockid: stocklockid})
          .then(res => {
            if(res.data.code == 0){
              this.qtyfree = res.data.data.qty_free
            }else{
              this.qtyfree = 0
            }
          })
          .catch((e) => {
            this.qtyfree = 0
          })

    }

    const checkmaxfree = function(){
      store
          .dispatch('stocklock/getfreestock', {stocklockid: this.stocklockid})
          .then(res => {
            if(res.data.code == 0){
              if(parseInt(this.qtyfree) > parseInt(res.data.data)){
                this.qtyfree = res.data.data
              }
            }else{
              this.qtyfree = 0
            }
          })
          .catch((e) => {
            this.qtyfree = 0
          })
    }

    const FreeSucess = function () {
      this.$refs.simpleRules1.validate().then(success => {
        if (success) {
          if(parseInt(this.qtyfree) == 0){
            toast.error("释放数量必须大于零")
            return false
          }
          if(confirm("确认执行此操作吗？")){
            store
                .dispatch('stocklock/freestock', {stocklockid: this.stocklockid,v: this.qtyfree})
                .then(res => {
                  if(res.data.code == 0){
                    toast.success("释放成功")
                    this.$bvModal.hide('freeLockModal')
                    refetchData()
                  }else{
                    toast.error(res.data.data)
                  }
                })
          }
        }
      })
    }

    const showLockModal = function (stocklockid){
      this.$bvModal.show('stocklockModal')
      this.stocklockid = stocklockid

      store
          .dispatch('stocklock/getstocklockinfo', {stocklockid: stocklockid})
          .then(res => {
            if(res.data.code == 0){
              this.stockid = res.data.data.stock_id
              this.qtylock = res.data.data.qty_lock
              this.starttime = res.data.data.ext.starttimestr
              this.endtime = res.data.data.ext.endtimestr
              this.reason = res.data.data.reason
            }else{
              this.$bvModal.hide('stocklockModal')
            }
          })
          .catch((e) => {
            this.$bvModal.hide('stocklockModal')
          })
    }

    const checkmax = function (){
      store
          .dispatch('stocklock/checkmax', {stockid: this.stockid,stocklockid:0})
          .then(res => {
            if(res.data.code == 0) {
              if (parseInt(this.qtylock) > parseInt(res.data.data.qty)) {
                this.qtylock = res.data.data.qty
              }
            }else{
              this.qtylock = 0
            }
          })
    }

    const ApplySucess = function (){
      this.$refs.simpleRules2.validate().then(success => {
        if (success) {
          if(parseInt(this.qtylock) == 0){
            toast.error("锁库数量必须大于零")
            return false
          }
          store
              .dispatch('stocklock/applysucess', {stocklockid: this.stocklockid,qtylock: this.qtylock,starttime: this.starttime,endtime: this.endtime})
              .then(res => {
                if(res.data.code == 0){
                  toast.success("审核成功")
                  this.$bvModal.hide('stocklockModal')
                  refetchData()
                }else{
                  toast.error(res.data.data)
                }
              })
        }
      })
    }

    const checkFaild = function(stocklockid){
      if(confirm("确认执行此操作吗？")) {
        store
            .dispatch('stocklock/checkfaild', {stocklockid: stocklockid})
            .then(res => {
              if (res.data.code == 0) {
                toast.success("操作成功")
                refetchData()
              }else {
                toast.error("操作失败")
              }
            })
      }
    }

    const addtostock = function (stocklockid){
      this.$bvModal.show('addLockModal')
      this.stocklockid = stocklockid
      this.addfree = 0
    }

    const checkmaxaddfree = function(){
      store
          .dispatch('stocklock/getaddfreestock', {stocklockid: this.stocklockid})
          .then(res => {
            if (res.data.code == 0) {
              if(parseInt(this.addfree)>parseInt(res.data.data)){
                this.addfree = res.data.data
              }
            }else{
              this.$bvModal.hide('addLockModal')
            }
          })
          .catch((e) => {
            this.$bvModal.hide('addLockModal')
          })
    }

    const addSucess = function(){
      this.$refs.simpleRules3.validate().then(success => {
        if (success) {
          if(parseInt(this.addfree) == 0){
            toast.error("追加数量必须大于零")
            return false
          }
          store
              .dispatch('stocklock/addlockstock', {stocklockid: this.stocklockid,v:this.addfree})
              .then(res => {
                if (res.data.code == 0) {
                  toast.success("追加成功")
                  this.$bvModal.hide('addLockModal')
                  refetchData()
                }else {
                  toast.error("追加失败")
                }
              })
        }
      })
    }

    const isGift = function (params) {
      if (params===1){
        return "(赠)"
      }else {
        return null
      }
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stocklockUseList({
      productid:productid,
      creator:creator
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      submitapply,
      cancelapply,
      showFreeLockQty,
      checkmaxfree,
      FreeSucess,
      showLockModal,
      checkmax,
      ApplySucess,
      checkFaild,
      addtostock,
      checkmaxaddfree,
      addSucess,
      isGift,
    }
  },

  created() {
    const userData = getUserData()
    this.userid = userData.user_id
    this.roleid = userData.role_id
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
